<template>
  <el-form :model="changePwdForm" ref="changePwdForm" :rules="rules" label-width="150px" size="small">
    <el-form-item label="请输入原密码" prop="oldPwd">
      <el-input type="password" autocomplete="off" v-model="changePwdForm.oldPwd"></el-input>
    </el-form-item>
    <el-form-item label="请输入新密码" prop="newPwd1">
      <el-input type="password" autocomplete="off" v-model.number="changePwdForm.newPwd1"></el-input>
    </el-form-item>
    <el-form-item label="请再次输入新密码" prop="newPwd2">
      <el-input type="password" autocomplete="off" v-model.number="changePwdForm.newPwd2"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm('changePwdForm')">提 交</el-button>
      <el-button @click="resetForm('changePwdForm')">取 消</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import utils from '../assets/js/utils'
export default {
  props: {
    changePwdForm: Object
  },
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else {
        if (this.changePwdForm.newPwd2 !== '') {
          this.$refs.changePwdForm.validateField('newPwd2')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'))
      } else if (value !== this.changePwdForm.newPwd1) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      rules: {
        oldPwd: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
        newPwd1: [{ validator: validatePass, trigger: 'blur' }],
        newPwd2: [{ validator: validatePass2, trigger: 'blur' }]
      }
    }
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          //获取当前用户名
          let user = utils.getUserCookie()

          console.log('user:', user)
          let params = {
            username: user.username,
            oldPwd: this.changePwdForm.oldPwd,
            newPwd: this.changePwdForm.newPwd1
          }

          this.$axios.put('/ChangePwd/', params).then(res => {
            console.log(res.data)
            if (res.data.code === 1000) {
              this.$message({
                type: 'success',
                message: '修改密码成功!',
                duration: 2000
              })
            } else {
              this.$message({
                type: 'warning',
                message: res.data.msg,
                duration: 2000
              })
            }
          })
          this.$emit('closeDlg')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.$emit('closeDlg')
    }
  }
}
</script>
