<template>
  <div class="header_containor">
    <el-dialog width="20%" title="修改密码" :visible.sync="pwdDlg_isShow" :close-on-click-modal="false">
      <changepwd-form :changePwdForm="changePwdForm" @closeDlg="closeDlg"></changepwd-form>
    </el-dialog>
    <header>
      <div class="l-content">
        <el-button plain icon="el-icon-menu" size="mini" @click="collapseMenu"></el-button>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="current.path" v-if="current">
            {{ current.label }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <el-dropdown trigger="click" size="mini" @command="handleCommand">
        <span class="el-dropdown-link">
          欢迎你！{{ user.name }}.<el-divider direction="vertical"></el-divider>个人信息<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <!-- <el-button type="primary" size="mini"> {{ user.name }} <i class="el-icon-arrow-down el-icon--right"></i> </el-button> -->
        <!-- <span class="el-dropdown-link"> <img :src="userImg" class="user" /> </span> -->
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-plus" command="password">修改密码</el-dropdown-item>
          <el-dropdown-item icon="el-icon-circle-plus" command="exit">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </header>
  </div>
</template>

<script>
import utils from '../assets/js/utils'
import { mapState } from 'vuex'
import ChangepwdForm from './ChangePwdForm'

export default {
  computed: {
    ...mapState({
      current: state => state.tab.currentMenu
    })
  },
  data() {
    return {
      userImg: require('@/assets/images/user.png'),
      pwdDlg_isShow: false,
      changePwdForm: {},
      user: utils.getUserCookie()
    }
  },
  components: {
    ChangepwdForm
  },
  methods: {
    collapseMenu() {
      this.$store.commit('collapseMenu')
    },
    exit() {
      this.$confirm('确定要退出系统吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        utils.clearToken()
        utils.clearUserCookie()
        this.$router.push({ name: 'login' })
      })
    },
    handleCommand(command) {
      if (command === 'password') {
        this.changePwdForm = {}
        this.pwdDlg_isShow = true
      } else if (command === 'exit') {
        this.exit()
      }
    },
    closeDlg() {
      this.pwdDlg_isShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  vertical-align: middle; /*设置该元素在父元素中的位置*/
}
.l-content {
  display: flex;
  align-items: center;
  .el-button {
    margin-right: 20px;
  }
}
.r-content {
  .user {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
}
.header_containor {
  vertical-align: middle; /*设置该元素在父元素中的位置*/
}
.el-dropdown-link {
  cursor: pointer;
  color: rgb(181, 201, 228);
}
.el-icon-arrow-down {
  font-size: 10px;
}
</style>
